@media print
{
  @page {
    size: auto;
    margin: 2rem;
  }

  .map-print-wrapper {
    width: 90%;
    margin: 0 auto;
  }
} 